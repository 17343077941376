import React from 'react';
import {
  m as motion,
  LazyMotion,
  domAnimation,
  AnimatePresence,
  useTransform,
  useViewportScroll,
} from 'framer-motion';
import '@resources/styles/app.scss';
import { ThemeProvider } from '@/components/ThemeContext';
import Header from '@/layouts/partials/Header';
import Footer from '@/layouts/partials/Footer';

export interface DefaultLayoutProps {
  children: React.ReactNode;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({ children }) => {
  const { scrollY } = useViewportScroll();
  const offsetY = useTransform(scrollY, [0, 100], ['-0', '-50px'], {
    clamp: false,
  });

  return (
    <ThemeProvider>
      <LazyMotion features={domAnimation}>
        <Header />
        <div className="relative overflow-hidden">
          <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
          <Footer />
          <motion.div
            style={{ y: offsetY, skewY: '-30deg' }}
            className="transform-gpu hidden fixed origin-top-right w-screen left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] max-w-none h-full top-50 bg-gradient-to-b -z-10 from-gray-900 opacity-75 xl:block"
          />
        </div>
      </LazyMotion>
    </ThemeProvider>
  );
};

export default DefaultLayout;
