import React, { RefObject } from 'react';

export interface ContainerProps extends React.ComponentPropsWithoutRef<'div'> {
  gutter?: boolean;
  gutterY?: boolean;
  width?: 'default' | 'bleed' | 'full' | 'narrow';
  tag?: 'div' | 'section' | 'header' | 'footer';
}

const getWidthClassName = (width: ContainerProps['width']) =>
  ({
    default: 'container',
    bleed: 'container',
    full: 'container relative w-screen left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] max-w-none px-container',
    narrow: 'container max-w-[85rem]',
  }[width ?? 'default']);

const Container = React.forwardRef<HTMLDivElement, ContainerProps>(
  (
    { width, tag = 'div', gutter, gutterY, className, children, ...props },
    ref,
  ) => {
    const Wrapper = tag;

    return (
      <Wrapper
        ref={ref as RefObject<HTMLDivElement>}
        className={`${getWidthClassName(width)} ${gutter ? 'px-gutter' : ''} ${
          gutterY ? 'py-gutter' : ''
        } ${className ?? ''}`}
        {...props}
      >
        {width === 'bleed' ? (
          <div className="relative -mx-base md:-mx-sm">{children}</div>
        ) : (
          children
        )}
      </Wrapper>
    );
  },
);

Container.defaultProps = {
  gutter: false,
  gutterY: false,
  width: 'default',
  tag: 'div',
};

export default Container;
