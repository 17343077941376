import React from 'react';
import {
  m as motion,
  useViewportScroll,
  useTransform,
  MotionProps,
} from 'framer-motion';
import useRelativeScrollProgress from '@/hooks/useRelativeScrollProgress';

export interface GradientTextProps
  extends React.HTMLAttributes<HTMLSpanElement> {
  accent?: Accent;
}

const GradientText: React.FC<GradientTextProps & MotionProps> = ({
  style,
  accent,
  className,
  children,
  ...any
}) => {
  const [ref, start, end] = useRelativeScrollProgress();

  const { scrollYProgress } = useViewportScroll();

  const backgroundPosition = useTransform(
    scrollYProgress,
    [start, end],
    ['0% 75%', '100% 0%'],
  );

  return (
    <motion.span
      ref={ref}
      style={{
        ...style,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        backgroundPosition: backgroundPosition as any,
      }}
      className={`-my-[0.25em] text-transparent py-[0.25em] inline-block bg-left transform-gpu bg-[length:200%_200%] bg-clip-text ${
        accent === 'yellow' ? 'bg-gradient-yellow' : 'bg-gradient-purple'
      } ${className ?? ''}`}
      {...any}
    >
      {children}
    </motion.span>
  );
};

GradientText.defaultProps = {
  accent: 'purple',
};

export default GradientText;
