import React, { forwardRef } from 'react';
import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby';

export interface LinkProps
  extends Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'> {}

export const isExternalLink = (to: string): boolean => !/^\/(?!\/)/.test(to);

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  ({ children, to, className, ...any }, ref): JSX.Element => {
    if (!isExternalLink(to)) {
      return (
        <GatsbyLink to={to} className={className} innerRef={ref} {...any}>
          {children}
        </GatsbyLink>
      );
    }

    return (
      <a
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        ref={ref}
        {...any}
      >
        {children}
      </a>
    );
  },
);

export default Link;
