import React, { useCallback, useContext } from 'react';
import Link, { LinkProps } from '@/components/Link';
import ThemeContext from '@/components/ThemeContext';

export interface HeaderNavigationItemProps
  extends React.ComponentPropsWithoutRef<'li'> {
  to: LinkProps['to'];
}

const HeaderNavigationItem: React.FC<HeaderNavigationItemProps> = ({
  to,
  children,
  ...props
}) => {
  const { hideMenu, headerHeight } = useContext(ThemeContext);

  const click = useCallback<React.MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      const hash = e.currentTarget.href.split('#')[1];
      const target = document.getElementById(hash) ?? undefined;

      hideMenu();

      if (target) {
        e.preventDefault();

        window.scrollTo({
          top:
            target.getBoundingClientRect().top +
            window.pageYOffset -
            headerHeight,
          behavior: 'smooth',
        });
      }
    },
    [headerHeight, hideMenu],
  );

  return (
    <li {...props}>
      <Link
        onClick={click}
        className="no-underline transition-colors text-inherit hover:text-white"
        to={to}
      >
        {children}
      </Link>
    </li>
  );
};

export default HeaderNavigationItem;
