import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { m as motion, useTransform, useViewportScroll } from 'framer-motion';
import Logo from '@resources/svg/logo.svg';
import LinkedInIcon from '@resources/svg/linkedin.svg';
import { Routes } from '@/enums/routes';
import HeaderNavigation from '@/layouts/partials/HeaderNavigation';
import ThemeContext from '@/components/ThemeContext';
import Container from '@/components/Container';
import Link from '@/components/Link';
import HeaderPlaceholder from './HeaderPlaceholder';
import Hamburger from '@/components/Hamburger';

const Header: React.FC = () => {
  const { scrollY } = useViewportScroll();
  const backgroundOpacity = useTransform(scrollY, [0, 64], [0, 1]);
  const backgroundY = useTransform(scrollY, [0, 64], [24, 0]);

  const { setHeaderHeight } = useContext(ThemeContext);
  const headerRef = useRef<HTMLElement>(null);

  const calculateHeaderDimensions = useCallback(() => {
    if (headerRef?.current) setHeaderHeight(headerRef.current.clientHeight);
  }, [headerRef, setHeaderHeight]);

  useEffect(() => calculateHeaderDimensions());

  useEffect(() => {
    window.addEventListener('resize', calculateHeaderDimensions);

    return () => {
      window.removeEventListener('resize', calculateHeaderDimensions);
    };
  }, [calculateHeaderDimensions]);

  return (
    <ThemeContext.Consumer>
      {({ menuActive, toggleMenu }) => (
        <header
          className="sticky inset-x-0 top-0 z-30 my-5 text-gray-200 transition-colors max:my-15"
          ref={headerRef}
        >
          <div className="relative z-30">
            <Container
              gutter
              className="relative z-40 flex items-center py-15-fixed"
            >
              <Link to={Routes.Home} className="text-white">
                <Logo className="w-auto h-40-fixed md:h-auto" />
              </Link>
              <HeaderNavigation
                className="hidden lg:ml-40 lg:block"
                innerClassName="flex items-center space-x-20"
              />
              <Hamburger
                id="menubutton"
                aria-haspopup="true"
                aria-controls="mainmenu"
                aria-expanded={menuActive}
                active={menuActive}
                onClick={() => toggleMenu()}
                className={`ml-auto transform-gpu ${
                  menuActive ? '' : 'lg:hidden'
                }`}
              />
              <Link
                to="https://www.linkedin.com/company/ancillarybox/"
                className="transition-colors sr-only hover:text-white lg:not-sr-only lg:ml-auto"
                title="Visit our LinkedIn profile"
              >
                <LinkedInIcon aria-hidden="true" />
                <span className="sr-only">Visit our LinkedIn profile</span>
              </Link>
            </Container>
            <motion.div
              style={{
                opacity: backgroundOpacity,
                y: backgroundY,
              }}
              className="absolute inset-0 shadow-2xl bg-gradient-navbar backdrop-blur-2xl -top-15 -z-20"
            />
            <motion.div
              className="fixed inset-x-0 top-0 z-10 text-white bg-gray-800 shadow-xl lg:hidden"
              aria-labelledby="menubutton"
              id="mainmenu"
              variants={{
                visible: { display: 'block', opacity: 1, y: 0 },
                hidden: {
                  opacity: 0,
                  y: -24,
                  transitionEnd: {
                    display: 'none',
                  },
                },
              }}
              initial="hidden"
              animate={menuActive ? 'visible' : 'hidden'}
              transition={{ duration: 0.5, type: 'spring' }}
            >
              <HeaderPlaceholder />
              <Container gutter className="py-40">
                <HeaderNavigation innerClassName="text-3xl space-y-5" />
              </Container>
            </motion.div>
          </div>
        </header>
      )}
    </ThemeContext.Consumer>
  );
};

export default Header;
