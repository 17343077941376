import React from 'react';
import { Routes } from '@/enums/routes';
import HeaderNavigationItem from './HeaderNavigationItem';

export interface HeaderNavigationProps
  extends Omit<React.ComponentPropsWithoutRef<'nav'>, 'children'> {
  innerClassName?: string;
}

const HeaderNavigation: React.FC<HeaderNavigationProps> = ({
  className,
  innerClassName,
  ...props
}) => (
  <nav className={`font-medium ${className ?? ''}`} {...props}>
    <ul className={innerClassName}>
      <HeaderNavigationItem to={`${Routes.Home}#products`}>
        Products
      </HeaderNavigationItem>
      <HeaderNavigationItem to={`${Routes.Home}#consulting`}>
        Consulting
      </HeaderNavigationItem>
      <HeaderNavigationItem to={`${Routes.Home}#team`}>
        About
      </HeaderNavigationItem>
      <HeaderNavigationItem to={`${Routes.Home}#contact`}>
        Contact
      </HeaderNavigationItem>
    </ul>
  </nav>
);

HeaderNavigation.defaultProps = {
  innerClassName: undefined,
};

export default HeaderNavigation;
