import React from 'react';
import ThemeContext from '@/components/ThemeContext';

const HeaderPlaceholder: React.FC<React.ComponentPropsWithoutRef<'div'>> = ({
  style,
  ...props
}) => (
  <ThemeContext.Consumer>
    {(theme) => (
      <div {...props} style={{ ...style, height: `${theme.headerHeight}px` }} />
    )}
  </ThemeContext.Consumer>
);

export default HeaderPlaceholder;
