import React, { useRef, useEffect } from 'react';

export interface HamburgerProps {
  label?: string;
  active?: boolean;
  onClick?: (active: boolean) => void;
}

const Hamburger: React.FC<
  HamburgerProps & React.HTMLAttributes<HTMLButtonElement>
> = ({
  label,
  onClick,
  active = false,
  id,
  tabIndex = 0,
  className,
  ...any
}) => {
  const svgAnimateActiveRef = useRef<(SVGAnimateElement | null)[]>([]);
  const svgAnimateInactiveRef = useRef<(SVGAnimateElement | null)[]>([]);

  function handleKeyPress(e: React.KeyboardEvent) {
    if (e.key !== ' ') return;

    e.preventDefault();

    if (onClick) onClick(!active);
  }

  useEffect(() => {
    (active ? svgAnimateActiveRef : svgAnimateInactiveRef).current.map(
      (current) => {
        if (current) current.beginElement();
        return current;
      },
    );
  }, [active]);

  return (
    <button
      type="button"
      className={`relative inline-block rounded p-xs group z-90 ${
        active ? 'text-highlight' : 'text-inherit'
      } ${className ?? ''}`}
      tabIndex={tabIndex}
      onClick={() => {
        if (onClick) onClick(!active);
      }}
      onKeyDown={(e) => handleKeyPress(e)}
      id={id}
      {...any}
    >
      <svg aria-hidden="true" width="32" height="32" viewBox="0 0 32 32">
        <g fill="none" fillRule="evenodd">
          <path d="M4,8 28,8" stroke="currentColor" strokeWidth="2">
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateActiveRef.current[0] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="350ms"
              to="M7,7 25,25"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateInactiveRef.current[0] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="350ms"
              to="M4,8 28,8"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
          </path>
          <path d="M4,16 28,16" stroke="currentColor" strokeWidth="2">
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateActiveRef.current[1] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="150ms"
              to="M5,16 23,16"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateActiveRef.current[2] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="opacity"
              dur="350ms"
              to="0"
            />
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateInactiveRef.current[1] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="350ms"
              to="M4,16 28,16"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateInactiveRef.current[2] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="opacity"
              dur="500ms"
              to="1"
            />
          </path>
          <path d="M4,24 28,24" stroke="currentColor" strokeWidth="2">
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateActiveRef.current[3] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="350ms"
              to="M7,25 25,7"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
            <animate
              ref={(ref: SVGAnimateElement) => {
                svgAnimateInactiveRef.current[3] = ref;
              }}
              fill="freeze"
              begin="indefinite"
              attributeName="d"
              dur="350ms"
              to="M4,24 28,24"
              keySplines="0.25 0 0.25 1; 0.25 0 0.25 1"
              calcMode="spline"
            />
          </path>
        </g>
      </svg>
      <span className="sr-only">{label ?? 'Menu'}</span>
    </button>
  );
};

Hamburger.defaultProps = {
  label: undefined,
  active: false,
  onClick: undefined,
};

export default Hamburger;
