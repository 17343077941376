import { useRef, useState, RefObject, useEffect } from 'react';

export type RefScrollProgress = [
  React.RefObject<HTMLDivElement>,
  number,
  number,
];

export default (inputRef?: RefObject<HTMLDivElement>): RefScrollProgress => {
  const ref = inputRef || useRef<HTMLDivElement>(null);

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      if (!ref.current) {
        return;
      }

      const rect = ref.current.getBoundingClientRect();

      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      const offsetTop = rect.top + scrollTop;

      const start = offsetTop + window.innerHeight * -0.5;

      setStart(
        offsetTop > window.innerHeight
          ? start / (document.body.clientHeight - window.innerHeight)
          : 0,
      );

      setEnd(
        (offsetTop + rect.height - window.innerHeight * -0.25 * -1) /
          (document.body.clientHeight - window.innerHeight),
      );
    };

    window.addEventListener('resize', handleResize, { passive: true });
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return [ref, start, end];
};
