import React, { useState, useEffect, useMemo } from 'react';

export interface ThemeContextProps {
  headerHeight: number;
  setHeaderHeight: (height: number) => void;
  menuActive: boolean;
  toggleMenu: (on?: boolean) => void;
  showMenu: () => void;
  hideMenu: () => void;
}

const themeContextDefaults = {
  headerHeight: 0,
  menuActive: false,
};

const ThemeContext = React.createContext<ThemeContextProps>(
  themeContextDefaults as ThemeContextProps,
);

const ThemeProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [menuActive, setMenuActive] = useState(themeContextDefaults.menuActive);
  const [headerHeight, setHeaderHeightState] = useState(
    themeContextDefaults.headerHeight,
  );

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (menuActive && e.key === 'Escape') {
        setMenuActive(false);
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, [menuActive]);

  const value = useMemo(
    () => ({
      headerHeight,
      setHeaderHeight: (height: number) => setHeaderHeightState(height),
      menuActive,
      toggleMenu: (on?: boolean) => setMenuActive(on ?? !menuActive),
      showMenu: () => setMenuActive(true),
      hideMenu: () => setMenuActive(false),
    }),
    [headerHeight, menuActive],
  );

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeContext;

export { ThemeProvider };
