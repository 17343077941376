import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import LogoMark from '@resources/svg/logo-mark.svg';
import EnvelopeIcon from '@resources/svg/envelope.svg';
import LinkedInIcon from '@resources/svg/linkedin.svg';
import MarkerIcon from '@resources/svg/marker.svg';
import { FooterQuery } from '@/../graphql-types';
import Container from '@/components/Container';
import GradientText from '@/components/GradientText';
import Link from '@/components/Link';

const Footer: React.FC = () => (
  <StaticQuery
    query={graphql`
      query Footer {
        site {
          siteMetadata {
            author
            linkedInUrl
          }
        }
      }
    `}
    render={({ site }: FooterQuery) => (
      <footer className="tracking-wide">
        <Container
          id="contact"
          className="py-10 md:font-light md:text-lg md:flex lg:grid lg:grid-cols-3"
          gutter
        >
          <div className="my-40 md:px-gutter">
            <h3 className="mt-0 mb-10 text-fluid-xl">
              <GradientText>
                Move faster.
                <br />
                Evolve smarter.
              </GradientText>
              <div>Contact us.</div>
            </h3>
            <ul className="flex items-center space-x-20-fixed">
              <li>
                <a href="mailto:info@ancillarybox.ai" title="Send us an email">
                  <EnvelopeIcon aria-hidden="true" />
                  <span className="sr-only">Send us an email</span>
                </a>
              </li>
              {site?.siteMetadata?.linkedInUrl && (
                <li>
                  <Link
                    to={site.siteMetadata.linkedInUrl}
                    title="Visit our LinkedIn profile"
                  >
                    <LinkedInIcon aria-hidden="true" />
                    <span className="sr-only">Visit our LinkedIn profile</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div className="my-40 md:px-gutter">
            <h4 className="flex items-center mt-0 mb-10 font-semibold text-same text-primary-500 space-x-15-fixed">
              <MarkerIcon />
              <span>United States</span>
            </h4>
            <address className="mb-10 not-italic">
              <strong>AncillaryBox</strong>
              <br />
              4733 Little Falls Road
              <br />
              Arlington, VA 22207
              <br />
              USA
            </address>
            <a
              href="mailto:info@ancillarybox.ai"
              className="transition-colors text-primary-500 hover:text-primary-400"
            >
              info@ancillarybox.ai
            </a>
          </div>
        </Container>
        <Container className="pb-40 text-sm px-gutter">
          <div className="md:px-gutter">
            <LogoMark className="mb-20 text-primary-500" />
            <ul className="space-y-10 md:flex md:space-y-0 md:space-x-10">
              <li>&copy; Copyright {new Date().getFullYear()}</li>
              <li>AncillaryBox℠</li>
              <li>All Rights Reserved</li>
            </ul>
          </div>
        </Container>
      </footer>
    )}
  />
);

export default Footer;
